  .ol-map {
    min-width: 300px;
    min-height: 240px;
    height: 500px;
    width: "100%";

  }

  .ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
  }

  .ol-full-screen {
    top: .5em;
    right: .5em;
  }

  .ol-viewport {
    min-width: 400px;
    min-height: 600px;
  }

  #msg {
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 5px);
    background-color: rgba(40, 40, 40, .8);
    padding: 10px;
    color: #eee;
    text-align: center;
    line-height: initial;
  }